import { HTTP } from "./http-common";

export default {
    async login(username, password) {
        const response = await HTTP({
            method: "POST",
            url: `/login`,
            data: { username, password },
        });
        return response.data.data;
    },
};
