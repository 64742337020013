import styleService from "../../services/style";
import APIError from "../../tools/APIError";
import notifier from "../../tools/notifier";

export default {
    namespaced: true,
    state: {},
    getter: {},
    actions: {
        async createStyle(context, { data, userId, template, name, header }) {
            try {
                const res = await styleService.createStyle(
                    data,
                    userId,
                    template,
                    name,
                    header,
                );
                notifier.success(
                    `Votre style ${res.data.name} est bien crée &#x1F603`,
                );
                return res;
            } catch (error) {
                APIError.handle(error);
            }
        },
        async generateStylePreview(context, { userId, header }) {
            try {
                const res = await styleService.generateStylePreview(
                    userId,
                    header,
                );
                return res;
            } catch (error) {
                APIError.handle(error);
            }
        },
        async getAll(context, { userId }) {
            try {
                const res = await styleService.getAll(userId);
                return res.data;
            } catch (error) {
                APIError.handle(error);
            }
        },
        async getStyle(context, { id }) {
            try {
                const res = await styleService.getStyle(id);
                return res.data;
            } catch (error) {
                APIError.handle(error);
            }
        },
        async deleteStyle(context, { id }) {
            try {
                const res = await styleService.deleteStyle(id);
                notifier.success(`Suppression effectué`);
                return res;
            } catch (error) {
                APIError.handle(error);
            }
        },
        async updateStyle(context, { id, data, userId, name, header }) {
            try {
                const res = await styleService.updateStyle(
                    id,
                    data,
                    userId,
                    name,
                    header,
                );
                notifier.success(`Sauvegarde réussite`);
                return res;
            } catch (error) {
                APIError.handle(error);
            }
        },
    },
};
