import pdfService from "../../services/pdf";
import APIError from "../../tools/APIError";
import notifier from "../../tools/notifier";
export default {
    namespaced: true,
    state: {},
    getter: {},
    actions: {
        async generate(context, { style, items, id }) {
            try {
                const data = await pdfService.generate(style, items, id);
                notifier.success(`Génération effectuée`);
                return data;
            } catch (error) {
                APIError.handle(error);
            }
        },
        async create(context, { data, template, name, userId, styleId }) {
            try {
                const res = await pdfService.create(
                    data,
                    template,
                    name,
                    userId,
                    styleId,
                );
                return res;
            } catch (error) {
                APIError.handle(error);
            }
        },
        async save(context, { pdfData }) {
            try {
                const res = await pdfService.save(pdfData);
                notifier.success(`Sauvegarde effectuée`);
                return res;
            } catch (error) {
                APIError.handle(error);
            }
        },
        async deletePdf(context, { id }) {
            try {
                const res = await pdfService.deletePdf(id);
                notifier.success(`Suppression effectuée`);
                return res;
            } catch (error) {
                APIError.handle(error);
            }
        },

        async deleteMap(context, { id, name, generationId, userId }) {
            try {
                const res = await pdfService.deleteMap(
                    id,
                    name,
                    generationId,
                    userId,
                );
                return res;
            } catch (error) {
                APIError.handle(error);
            }
        },
        async getAll(context, { userId }) {
            try {
                const res = await pdfService.getAll(userId);
                return res.data;
            } catch (error) {
                APIError.handle(error);
            }
        },
        async getPdfFiltred(context, { userId }) {
            try {
                const res = await pdfService.getPdfFiltred(userId);
                return res.data;
            } catch (error) {
                APIError.handle(error);
            }
        },
        async deletepdffiles(context, { userId }) {
            try {
                const res = await pdfService.deletepdffiles(userId);
                return res.data;
            } catch (error) {
                APIError.handle(error);
            }
        },
        async getPdf(context, { id }) {
            try {
                const res = await pdfService.getPdf(id);
                return res.data;
            } catch (error) {
                APIError.handle(error);
            }
        },

        async generatePreview(context, { items, userId, template, style }) {
            try {
                const res = await pdfService.generatePreview(
                    items,
                    userId,
                    template,
                    style,
                );
                return res;
            } catch (error) {
                APIError.handle(error);
            }
        },
        async generateMapPreview(
            context,
            {
                userId,
                template,
                profilSecondaryColor,
                profilPrimaryColor,
                traceColor,
            },
        ) {
            try {
                const res = await pdfService.generateMapPreview(
                    userId,
                    template,
                    profilSecondaryColor,
                    profilPrimaryColor,
                    traceColor,
                );
                return res;
            } catch (error) {
                APIError.handle(error);
            }
        },
        async changeStyleId(context, { styleId, pdfId, template }) {
            try {
                const res = await pdfService.changeStyleId(
                    styleId,
                    pdfId,
                    template,
                );
                return res;
            } catch (error) {
                APIError.handle(error);
            }
        },
        async generateHeader(context, { userId, genId, header }) {
            try {
                const res = await pdfService.generateHeader(
                    userId,
                    genId,
                    header,
                );
                return res;
            } catch (error) {
                APIError.handle(error);
            }
        },
    },
};
