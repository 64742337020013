import { HTTP } from "./http-common";

export default {
    createStyle,
    getAll,
    getStyle,
    deleteStyle,
    updateStyle,
    generateStylePreview,
};

async function createStyle(data, userId, template, name, header) {
    try {
        let bodyFormData = new FormData();
        bodyFormData.set("data", data);
        bodyFormData.append("userId", userId);
        bodyFormData.append("template", template);
        bodyFormData.append("name", name);
        bodyFormData.append("file", header);

        const response = await HTTP({
            method: "POST",
            url: `/style/create`,
            headers: { "Content-Type": "multipart/form-data" },
            data: bodyFormData,
        });

        return response.data;
    } catch (error) {
        throw error.response;
    }
}
async function generateStylePreview(userId, header) {
    try {
        let bodyFormData = new FormData();

        bodyFormData.append("userId", userId);
        bodyFormData.append("file", header);

        const response = await HTTP({
            method: "POST",
            url: `/style/generatepreview`,
            headers: { "Content-Type": "multipart/form-data" },
            data: bodyFormData,
        });

        return response.data;
    } catch (error) {
        throw error.response;
    }
}

async function getAll(userId) {
    try {
        const response = await HTTP({
            method: "GET",
            url: `/style/${userId}/user`,
        });

        return response.data;
    } catch (error) {
        throw error.response;
    }
}

async function getStyle(id) {
    try {
        const response = await HTTP({
            method: "GET",
            url: `/style/${id}`,
        });

        return response.data;
    } catch (error) {
        throw error.response;
    }
}

async function deleteStyle(id) {
    try {
        const response = await HTTP({
            method: "DELETE",
            url: `/style/delete`,
            data: {
                id: id,
            },
        });
        return response;
    } catch (error) {
        throw error.response;
    }
}

async function updateStyle(id, data, userId, name, header) {
    try {
        let bodyFormData = new FormData();
        bodyFormData.set("data", data);
        bodyFormData.append("userId", userId);
        bodyFormData.append("id", id);
        bodyFormData.append("name", name);
        if (header) {
            bodyFormData.append("file", header);
        }

        const response = await HTTP({
            method: "PUT",
            url: `/style/update`,
            headers: { "Content-Type": "multipart/form-data" },
            data: bodyFormData,
        });
        return response;
    } catch (error) {
        throw error.response;
    }
}
