import Vue from "vue";
import VueRouter from "vue-router";

import store from "../store";
Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "Login",
        component: function () {
            return import("../views/Login.vue");
        },
    },
    {
        path: "/user/create",
        name: "CreateUser",
        component: function () {
            return import("../views/user/CreateUser.vue");
        },
    },
    {
        path: "/user/admin",
        name: "AdminUser",
        component: function () {
            return import("../views/user/AdminUser.vue");
        },
        meta: {
            admin: true,
        },
    },
    {
        path: "/home",
        name: "Home",
        component: function () {
            return import("../views/HomeNew.vue");
        },
        meta: {
            member: true,
        },
    },
    {
        path: "/modele/:id",
        name: "Style",
        component: function () {
            return import("../views/StyleNew.vue");
        },
        meta: {
            member: true,
        },
    },
    {
        path: "/pdfs",
        name: "Pdf",
        component: function () {
            return import("../views/Pdf.vue");
        },
        meta: {
            member: true,
        },
    },
    {
        path: "/wiki",
        name: "Wiki",
        component: function () {
            return import("../views/Wiki.vue");
        },
        meta: {
            member: true,
        },
    },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

router.beforeEach(async (to, from, next) => {
    // On récupere l'utilisateur dans le store (persistant)
    const user = store.state.user.user;

    if (to.matched.some(r => r.meta.member)) {
        if (!user) {
            return next({ name: "Login" });
        }
    } else if (to.matched.some(r => r.meta.admin)) {
        if (!user) {
            return next({ name: "Login" });
        } else if (user.credentials < 10) {
            return next({ name: "Home" });
        }
    }
    next();
});

export default router;
