import Vue from "vue";
import Vuex from "vuex";
import pdfModule from "./modules/pdf";
import userModule from "./modules/user";
import styleModule from "./modules/style";
import notifyModule from "./modules/notify";

import createPersistedState from "vuex-persistedstate";
import NotifyPlugin from "./plugins/notify";

import notifer from "../tools/notifier";

import SecureLS from "secure-ls";
const ls = new SecureLS({ isCompression: false });

Vue.use(Vuex);

const store = new Vuex.Store({
    state: {},
    mutations: {},
    actions: {},
    modules: {
        pdf: pdfModule,
        user: userModule,
        style: styleModule,
        notify: notifyModule,
    },
    plugins: [
        NotifyPlugin,
        createPersistedState({
            paths: ["user"],
            storage: {
                getItem: key => ls.get(key),
                setItem: (key, value) => ls.set(key, value),
                removeItem: key => ls.remove(key),
            },
        }),
        createPersistedState({
            paths: ["pdf", "style"],
            storage: sessionStorage,
        }),
    ],
});

notifer.attachStore(store);

export default store;
