import userService from "../../services/user";
import APIError from "../../tools/APIError";
import notifier from "../../tools/notifier";
import authService from "../../services/auth";

export default {
    namespaced: true,
    state: {
        status: {
            loggingIn: false,
            loggedIn: false,
        },
        user: null,
        access_token: null,
        refresh_token: null,
    },
    getter: {},
    actions: {
        async create(context, { mail, password, username, organism }) {
            try {
                const user = await userService.create(
                    mail,
                    password,
                    username,
                    organism,
                );
                notifier.success(`Utilisateur créer`);
                return user;
            } catch (error) {
                APIError.handle(error);
            }
        },
        async logout({ commit }) {
            try {
                commit("logout");
            } catch (error) {
                APIError.handle(error);
            }
        },

        async login({ commit }, { username, password }) {
            commit("loginRequest", { username });
            try {
                const data = await authService.login(username, password);
                commit("loginSuccess", data);
                notifier.success(
                    `Connecté<br/>Bienvenue ${data.user.username} &#x1F603`,
                );
                return true;
            } catch (error) {
                commit("loginFailure");
                APIError.handle(error);
            }
        },
        async getAllUsers() {
            try {
                const users = await userService.getAll();
                return users;
            } catch (error) {
                APIError.handle(error);
            }
        },
        async updateUser({ commit }, { id, templates, credentials }) {
            try {
                let response = await userService.updateUser(
                    id,
                    templates,
                    credentials,
                );
                commit("updateUser", { id, templates, credentials });
                notifier.success(`Utilisateur bien mis à jour &#x1F603`);
                return response;
            } catch (error) {
                APIError.handle(error);
            }
        },
    },

    mutations: {
        loginRequest(state, user) {
            state.status.loggingIn = true;
            state.status.loggedIn = false;
            state.user = user;
        },
        loginSuccess(state, { user, access_token, refresh_token }) {
            state.status.loggingIn = false;
            state.status.loggedIn = true;
            state.user = user;
            state.access_token = access_token;
            state.refresh_token = refresh_token;
        },
        loginFailure(state) {
            state.status.loggingIn = false;
            state.status.loggedIn = false;
            state.user = null;
            state.access_token = null;
            state.refresh_token = null;
        },
        logout(state) {
            state.user = null;
        },
        updateUser(state, { id, templates, credentials }) {
            if (id == state.user.id) {
                if (credentials) {
                    state.user.credentials = credentials;
                }
                if (templates) {
                    state.user.templates = templates;
                }
            }
        },
    },
};
