import { HTTP } from "./http-common";

export default {
    create,
    getAll,
    updateUser,
};

async function create(mail, password, username, organism) {
    const response = await HTTP({
        method: "POST",
        url: `/user`,
        data: {
            mail: mail,
            password: password,
            username: username,
            organism: organism,
            credentials: "0",
        },
    });
    return response.data;
}

async function getAll() {
    const response = await HTTP({
        method: "GET",
        url: `/user`,
    });
    return response.data.data;
}

async function updateUser(id, templates, credentials) {
    const response = await HTTP({
        method: "PUT",
        url: `/user/${id}`,
        data: {
            templates: templates,
            credentials: credentials,
        },
    });
    return response.data.data;
}
