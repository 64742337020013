import { HTTP } from "./http-common";

export default {
    generate,
    create,
    getAll,
    getPdf,
    save,
    deleteMap,
    deletePdf,
    generatePreview,
    generateMapPreview,
    getPdfFiltred,
    changeStyleId,
    generateHeader,
    deletepdffiles,
};

async function generate(style, items, id) {
    try {
        const response = await HTTP({
            method: "POST",
            url: `/pdf/generate`,
            responseType: "arraybuffer",
            data: { style: style, items: items, id: id },
        });

        return response;
    } catch (error) {
        throw error.response;
    }
}
async function save(pdfData) {
    try {
        const response = await HTTP({
            method: "POST",
            url: `/pdf/save`,
            data: {
                pdfData: pdfData,
            },
        });
        return response;
    } catch (error) {
        throw error.response;
    }
}

async function generatePreview(items, userId, template, style) {
    try {
        const response = await HTTP({
            method: "POST",
            url: `/pdf/generateprewiew`,
            data: {
                items: items,
                userId: userId,
                template: template,
                style: style,
            },
        });

        return response.data.data;
    } catch (error) {
        throw error.response;
    }
}

async function deletePdf(id) {
    try {
        const response = await HTTP({
            method: "DELETE",
            url: `/pdf/delete`,
            data: {
                id: id,
            },
        });
        return response;
    } catch (error) {
        throw error.response;
    }
}

async function deleteMap(id, name, generationId, userId) {
    try {
        const response = await HTTP({
            method: "POST",
            url: `/pdf/delete/map`,
            data: {
                id: id,
                name: name,
                generationId: generationId,
                userId: userId,
            },
        });
        return response;
    } catch (error) {
        throw error.response;
    }
}

async function create(data, template, name, userId, styleId) {
    try {
        const response = await HTTP({
            method: "POST",
            url: `/pdf/create`,
            data: {
                data: data,
                template: template,
                name: name,
                userId: userId,
                styleId: styleId,
            },
        });

        return response;
    } catch (error) {
        throw error.response;
    }
}

async function getAll(userId) {
    try {
        const response = await HTTP({
            method: "GET",
            url: `/pdf/${userId}/user`,
        });

        return response.data;
    } catch (error) {
        throw error.response;
    }
}
async function getPdfFiltred(userId) {
    try {
        const response = await HTTP({
            method: "GET",
            url: `/pdf/filtred/${userId}/user`,
        });

        return response.data;
    } catch (error) {
        throw error.response;
    }
}

async function getPdf(id) {
    try {
        const response = await HTTP({
            method: "GET",
            url: `/pdf/${id}`,
        });

        return response.data;
    } catch (error) {
        throw error.response;
    }
}

async function deletepdffiles(userId) {
    try {
        const response = await HTTP({
            method: "GET",
            url: `/pdf/deletepdffiles/${userId}`,
        });

        return response.data;
    } catch (error) {
        throw error.response;
    }
}

async function generateMapPreview(
    userId,
    template,
    profilSecondaryColor,
    profilPrimaryColor,
    traceColor,
) {
    try {
        const response = await HTTP({
            method: "POST",
            url: `/map/generatePreview`,
            data: {
                userId: userId,
                template: template,
                profilSecondaryColor: profilSecondaryColor,
                profilPrimaryColor: profilPrimaryColor,
                traceColor: traceColor,
            },
        });

        return response.data;
    } catch (error) {
        throw error.response;
    }
}

async function changeStyleId(styleId, pdfId, template) {
    try {
        const response = await HTTP({
            method: "POST",
            url: `/pdf/changeid`,
            data: {
                styleId: styleId,
                pdfId: pdfId,
                template: template,
            },
        });

        return response.data;
    } catch (error) {
        throw error.response;
    }
}

async function generateHeader(userId, genId, header) {
    try {
        let bodyFormData = new FormData();
        bodyFormData.set("genId", genId);
        bodyFormData.append("userId", userId);
        bodyFormData.append("file", header);

        const response = await HTTP({
            method: "POST",
            url: `/pdf/generateheader`,
            headers: { "Content-Type": "multipart/form-data" },
            data: bodyFormData,
        });

        return response.data;
    } catch (error) {
        throw error.response;
    }
}
